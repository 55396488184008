import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <div>404 Page</div>
    </Layout>
  );
};

export default NotFoundPage;
